
import React from 'react';
import {Link, NavLink} from "react-router-dom";

// Images Common File
import * as Img from '../Img';

// Css File
import '../../css/sidemenu.css'

// Design Files
import { useMenu } from './MenuContext';
import Socialicon from '../Socialicon';

export default function Sidemenu() {

    const { isActive, toggleMenu } = useMenu();

    return (
        <>
            <aside className={`side-menu-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                <div className="smb-logo-bx">
                    <span style={{top:"17px"}} className="close-icon white trigger-closed" onClick={toggleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                    </span>
                    <Link to="/" className="smb-logo-link">
                        <img className="logo-img" src={Img.logo} alt="" />
                        {/* <img className="icon-anim" src={Img.icon_anim} alt="" /> */}
                    </Link>
                </div>

                <div className="smb-menu-bx">

                    <div className="smb-signinup-btn mt-2">
                        <div  data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-fill cbi-big">Inquiry</div>
                    </div>

                    {/* <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Markets</div>
                        <li><Link to="" className="smb-link">Indices</Link></li>
                        <li><Link to="" className="smb-link">Stocks</Link></li>
                        <li><Link to="" className="smb-link">Cryptocurrency</Link></li>
                        <li><Link to="" className="smb-link">Commodities</Link></li>
                        <li><Link to="" className="smb-link">Gold and Silver</Link></li>
                    </ul> */}

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Useful Links</div>
                        <li><NavLink to="/" className="smb-link">Home</NavLink></li>
                        <li><NavLink to="/trading-rules" className="smb-link">Trading Rules</NavLink></li>
                        {/* <li><NavLink to="/about" className="smb-link">About Us</NavLink></li> */}
                        <li><Link to="/" className="smb-link">Blog</Link></li>
                        <li><NavLink to="/contact" className="smb-link">Contact Us</NavLink></li>
                    </ul>

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Legal</div>
                        <li><NavLink to="/faqs" className="smb-link">FAQs</NavLink></li>
                        <li><NavLink to="/privacy-policy" className="smb-link">Privacy Policy</NavLink></li>
                        <li><NavLink to="/terms-and-conditions" className="smb-link">Terms & Conditions</NavLink></li>
                    </ul>

                    <div className="py-5"></div>

                </div>

                <div className="smb-footer">
                    <Socialicon className="social-icon justify-content-center" />
                </div>

            </aside>

            <div className={`overlaydiv ${isActive ? 'active' : ''}`} onClick={toggleMenu}></div>
        </>
    );
};



