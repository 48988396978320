import React from 'react';
import { Helmet } from "react-helmet-async";

// Img
import banner from "../../img/bgimg/8866.jpg"
import ivfb from "../../img/bgimg/hero2-dashboard.png"

// Video
// import video1 from "../../img/video/video(3).webm"
// import video2 from "../../img/video/video(3).mp4"


// Design File
import IndexSection from './IndexSection';
// import Highlight from './Highlight';
import Seonumber from './Seonumber';
import TradingModes from './TradingModes';
import PlatformWorks from './PlatformWorks';
import BecomePro from './BecomePro';
import StoreSection from '../../components/StoreSection';
import Partnership from './Partnership';
import AccountStepsInfo from './AccountStepsInfo';
import Pricing from './Pricing';
import { Link } from 'react-router-dom';


// Custom hook to detect if the user is using any Apple device
// const useIsAppleDevice = () => {
//   const [isAppleDevice, setIsAppleDevice] = useState(false);

//   useEffect(() => {
//     const checkAppleDevice = () => {
//       const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//       console.log("User Agent:", userAgent); // Log the user agent to verify it
//       if (/iPhone|iPad|iPod|MacIntel|Mac|Apple|Macintosh/i.test(userAgent)) {
//         setIsAppleDevice(true);
//         console.log("Apple device detected.");
//       } else {
//         console.log("Apple device not detected.");
//       }
//     };
//     checkAppleDevice();
//   }, []);

//   return isAppleDevice;
// };

export default function PageHome() {

  const questions = [
    {
      question: "How can slippage occur?",
      answer: (
        <>
            <p>Slippage refers to the difference between the expected price of a trade and the price at which the trade is executed.</p>
            <p>It can occur during volatile moments, for example during a high-impact news event in retrospect to the affected currency. This is something we can not control.</p>
            <p>Manage your risk wisely if you hold trades during these moments.</p>
        </>
      ),
    },

    {
      question: "How to log in to Match Trader?",
      answer: (
        <>
            <ul>
              <li>Sign in to your Funded Firm dashboard or click below.</li>
              <li>Choose your account and click on credentials.</li>
              <li>Copy your account password.</li>
              <li>Click on Start Trading</li>
              <li>Write the email address that you used while logging into your Funded Firm account.</li>
              <li>Paste your account password that was copied from the credentials pop-up on your dashboard.</li>
              <li>Click sign in</li>
            </ul>
        </>
      ),
    },

    {
      question: "How do I request a Payout?",
      answer: (
        <>
            <p>In your dashboard on our website, you can see "Payouts" in the menu. By filling in this form you can request your payout. If you fill in this form the connected trading account will be switched to view-only mode until the payout is processed, therefore all trades have to be closed. The minimum amount for a payout is 1% of the initial balance including our split.</p>

            <p>Payouts below 500$ will be processed through crypto USDT TRC20.</p>
            <p>Payouts above 500$ will be processed via Rise. Rise supports both bank transfer and crypto.</p>
            <p>*If Rise does not support the country, the payout will be processed without Rise through crypto. The list of countries that are not supported by Rise: </p>
        </>
      ),
    },
  ];
  
  // const videoRef = useRef(null);
  // const [showVideo, setShowVideo] = useState(false);

  // useEffect(() => {
  //     // Set a timer to show the video after 5 seconds
  //     const timer = setTimeout(() => {
  //         setShowVideo(true);
  //         if (videoRef.current) {
  //             videoRef.muted = true;
  //             videoRef.current.play();
  //             videoRef.current.play().catch((error) => {
  //               console.log('Autoplay failed:', error);
  //             });
  //         }
  //     }, 5000);

  //     // Cleanup the timer on component unmount
  //     return () => clearTimeout(timer);
  // }, []);

  // const isAppleDevice = useIsAppleDevice();

  return (
    <>
      <Helmet>
        <title>Funded Firm</title>
      </Helmet>

      <section className="home-main-section">
        {/* <div className="container-lg cl-custome">
          <IndexSection />
        </div> */}

        <div className={`common-page-bg-bx`}>
          <img src={banner} alt='' />
        </div>

        {/* <div className={`common-page-bg-bx ${showVideo ? 'cpbg-op1' : 'cpbg-op0'}`}>
        {isAppleDevice ? (
          <img src={banner} alt='' />
 
        ) : (
          <video ref={videoRef} poster={banner} disablePictureInPicture autoPlay muted loop playsInline >
            <source src={video2} type="video/mp4" />
            <source src={video1} type="video/webm" />
          </video>
        )}
        </div> */}
      
      </section>

      <section className="hms-affter">
        <div className="container-lg cl-custome">
          <IndexSection />
        </div>
      </section>

      {/* <section className="d-none d-none">
        <div className="container-lg cl-custome">
            <Highlight />
        </div>
      </section> */}
 
      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <Partnership />
        </div>
      </section>


      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
          <AccountStepsInfo />
        </div>
      </section>


       
      <section className=" mt-5 py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <div className="row justify-content-center align-items-center">

            <div className="col-lg-5">
              <div>
                <img className="grayscale-img" style={{maxWidth:"420px",width:"100%",margin:"auto",display:'block'}} src={ivfb} alt='' />
              </div>
            </div>

            <div className="col-lg-7 mt-4 mt-lg-0">
              <div className="common-heading-bx">
                <div className="common-heading ch-big">Built By Traders For Traders. <br />              Your Trading Ideas, Our Risk.</div>
                <div className="common-sub-heading ch-big">Funded Firm believes that traders go through multiple levels in their careers, Student - Practitioner - Senior - Master, with FP's in house-built evaluation models and funded (Master), which's built to find trading talents while helping them get through their funding problems, by offering them the possibility to trade up $300.000.</div>
              </div>
            </div>

          </div>
        </div>
      </section>

       
      <section className=" mt-md-5 py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <div className="relative app-download-bx py-5 px-3 px-lg-5">
            <div className="common-heading-bx">
                <div className="common-heading ch-big text-center">Stay updated with our in depth trading rules.</div>
                <div className="common-sub-heading text-center mt-2">Trading Objectives are a key element to evaluate a trader's skills, meeting the trading objectives proves that the trader is disciplined and consiste</div>
                <Link to="/trading-rules" className="mx-auto common-btn-item cbi-outline mt-2">Trading Rules</Link>
            </div>
          </div>
        </div>
      </section>




      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
        <TradingModes />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
        <Seonumber />
        </div>
      </section>

      <section className="py-5">
        <div className="container-lg cl-custome">
        <BecomePro />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
        <div className="container-lg cl-custome">
        <Pricing />
        </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
        <PlatformWorks />
        </div>
      </section>

      <section className="py-5 bfgndtg overflowhidden">
          <div className="container-lg cl-custome legal-content">
            <div className="common-heading-bx d-none d-md-block">
                <div className="common-heading ch-big">Frequently Asked Questions</div>
            </div>
            <div id="faqsaccordion" className="faqs-accordion  px-0 mt-2"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
                <div data-aos="fade-up" className="acc-item" key={index}>
                    <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        {item.question}
                        <span className="down-icon"></span>
                    </div>

                    <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                        <div className="faqs-data">
                            {item.answer}
                        </div>
                    </div>
                </div>
              ))}
            </div>

            <div className='mt-3 d-flex justify-content-center' data-aos="fade-up">
            <Link to="/faqs" className="common-btn-item cbi-outline">View more</Link>
            </div>
          </div>
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <StoreSection />
        </div>
      </section>


    </>
  );
};
