import React from 'react'
// import { TypeAnimation } from 'react-type-animation';

// Css File
import "../../css/IndexSection.css"

export default function IndexSection() {
  return (
    <div className="textlayout-main-bx py-0 py-lg-5">
        <div data-aos="fade-up" className="tlm-heading text-center gsdhgrx">
          Your Skill Is <br/> Our Capital.
          {/* <TypeAnimation
            sequence={[
              "Our Capital",
              700,
              "Your Profit",
              700
            ]}
            wrapper="span"
            style={{ whiteSpace: 'pre-line', display: 'block', overflow:'hidden' }}
            speed={50}
            repeat={Infinity}
          /> */}
        </div>
        {/* <div data-aos="fade-up" className="tlm-heading">They're <span className="tlm-h-color">Fast</span> <br/> We're Faster</div> */}
        <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">We risk while you receive <br className='d-block d-sm-none'/> up to 90% of the Profit.</div>
        <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <div className="common-btn-item cbi-fill"><span>Join discord</span></div>
            <div className="common-btn-item cbi-outline"><span>Buy challenge</span></div>
        </div>
    </div>
  )
}
