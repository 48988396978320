import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PageFAQs() {

  const questions = [
    {
      question: "How can slippage occur?",
      answer: (
        <>
            <p>Slippage refers to the difference between the expected price of a trade and the price at which the trade is executed.</p>
            <p>It can occur during volatile moments, for example during a high-impact news event in retrospect to the affected currency. This is something we can not control.</p>
            <p>Manage your risk wisely if you hold trades during these moments.</p>
        </>
      ),
    },

    {
      question: "How to log in to Match Trader?",
      answer: (
        <>
            <ul>
              <li>Sign in to your Funded Firm dashboard or click below.</li>
              <li>Choose your account and click on credentials.</li>
              <li>Copy your account password.</li>
              <li>Click on Start Trading</li>
              <li>Write the email address that you used while logging into your Funded Firm account.</li>
              <li>Paste your account password that was copied from the credentials pop-up on your dashboard.</li>
              <li>Click sign in</li>
            </ul>
        </>
      ),
    },

    {
      question: "How do I request a Payout?",
      answer: (
        <>
            <p>In your dashboard on our website, you can see "Payouts" in the menu. By filling in this form you can request your payout. If you fill in this form the connected trading account will be switched to view-only mode until the payout is processed, therefore all trades have to be closed. The minimum amount for a payout is 1% of the initial balance including our split.</p>

            <p>Payouts below 500$ will be processed through crypto USDT TRC20.</p>
            <p>Payouts above 500$ will be processed via Rise. Rise supports both bank transfer and crypto.</p>
            <p>*If Rise does not support the country, the payout will be processed without Rise through crypto. The list of countries that are not supported by Rise: </p>
        </>
      ),
    },

    {
      question: "What is the IP rule?",
      answer: (
        <>
            <p>The region of your IP address(es) used to log into the Master account must remain consistent. If our Risk Team detects a change in the region, they may contact you to confirm this and request proof, such as an airline ticket, passport stamp, or live video of the location.</p>
        </>
      ),
    },

    {
      question: "How Payouts With Rise Work?",
      answer: (
        <>
            <p>Rise is a financial services company that specializes in payout processing, similar to Deel. Through our platform, you have the option to withdraw your payout to either your cryptocurrency wallet or bank account. In order to your payout processed with rise, you must first sign up through the invitation sent by Rise to your email , complete the KYC process, and sign the necessary agreement. Once these steps are completed and our team verifies the process, your payout will be deposited into your Rise account. Please note that when creating a Rise account, the email used must match the one associated with your Funded Firm account.</p>
        </>
      ),
    },

    {
      question: "Made a partial crypto currency transaction, what next?",
      answer: (
        <>
            <p>Reach out to support via email at <Link to="mailto:info@fundedfirm.com" target="_blank">info@fundedfirm.com</Link> The team will act on it.</p>
            <p>Always share the screenshot of the transaction number or the hash reference number with your email. We will reply with a new payment link that should be used to complete the transaction.</p>
        </>
      ),
    },

  ];

  return (
    <>

      <Helmet>
        <title>FAQs | Funded Firm</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ FAQs</div>
            </div>
            <div className="tlm-heading heading-24">Frequently Asked Question</div>
            {/* <div className="tlm-sub-text">Unlock the answers you need with our FAQs. From account setup to trading strategies, find quick solutions to common queries, ensuring your trading journey with Funded Firm is smooth and successful.</div> */}
          </div>
        </div>
      </section>

      <section className="pb-5">
          <div className="container-lg cl-custome legal-content">
            <div id="faqsaccordion" className="faqs-accordion  px-0 mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
                <div data-aos="fade-up" className="acc-item" key={index}>
                    <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        {item.question}
                        <span className="down-icon"></span>
                    </div>

                    <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                        <div className="faqs-data">
                            {item.answer}
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
      </section>

    </>
  );
};
