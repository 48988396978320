import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PageTermsAndConditions() {
  return (
    <>

      <Helmet>
        <title>Terms & Conditions | Funded Firm</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Terms & Conditions</div>
            </div>
            <div className="tlm-heading heading-24">Terms & Conditions</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
          <div className="container-lg cl-custome legal-content">

            <div className="main-sub-lable">Overview</div>
            <p>This website, Funded Firm (also referred to as “The Company”), offers this website, including all
information, tools and services available from this site to you, the user, conditional to your acceptance
of all terms, conditions, policies and notices stated here.
</p>
<p>By using our site and/or purchasing something from us, you engage in our “Service” and agree to be
bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional
terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of
Service apply to all users of the site, including without limitation users who are browsers, vendors,
customers, merchants, and/ or contributors of content.
</p>
<p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any
part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and
conditions of this agreement, then you may not access the website or use any services. If these Terms of
Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
<p>The Services are only intended for persons over the age of 18 residing in the country for which the
Services are available. By registering on the Website, you confirm that you are over 18 years of age. If
you are under 18 years of age, you may not use the Services. You undertake to access the Services solely
from one of the countries for which the Services are available. You acknowledge that your access to and
use of the Services may be restricted or prohibited by law in some countries, and you undertake to only
access and use the Services in accordance with applicable laws.
</p>
<p>Any new features or tools which are added to the current store (under provided accounts section) shall
also be subject to the Terms of Service. You can review the most current version of the Terms of
Service at any time on this page. We reserve the right to update, change or replace any part of these
Terms of Service by posting updates and/or changes to our website. It is your responsibility to check
this page periodically for changes. Your continued use of or access to the website following the posting
of any changes constitutes acceptance of those changes.</p>
<p>NONE OF THE SERVICES PROVIDED TO YOU BY THE PROVIDER CAN BE CONSIDERED INVESTMENT
SERVICES IN ACCORDANCE WITH APPLICABLE LAWS. THE PROVIDER DOES NOT GIVE OR PROVIDE TO
YOU ANY GUIDANCE, INSTRUCTIONS, OR INFORMATION ABOUT HOW OR IN WHICH MANNER YOU
SHOULD PERFORM TRANSACTIONS WHEN USING THE SERVICES OR OTHERWISE, OR ANY OTHER
SIMILAR INFORMATION ABOUT THE INVESTMENT TOOLS TRADED, NOR DOES THE PROVIDER ACCEPT
ANY SUCH GUIDANCE, INSTRUCTIONS, OR INFORMATION FROM YOU. NONE OF THE SERVICES
CONSTITUTE INVESTMENT ADVICE OR RECOMMENDATIONS. NO EMPLOYEES, STAFF, OR
REPRESENTATIVES OF THE PROVIDER ARE AUTHORIZED TO PROVIDE INVESTMENT ADVICE OR
RECOMMENDATIONS. SHOULD ANY INFORMATION OR STATEMENT OF ANY EMPLOYEE, STAFF, OR
REPRESENTATIVES OF THE PROVIDER BE INTERPRETED AS INVESTMENT ADVICE OR
RECOMMENDATIONS, THE PROVIDER EXPLICITLY DISCLAIMS THAT THE SAME IS INVESTMENT ADVICE
OR RECOMMENDATIONS AND SHALL NOT BE RESPONSIBLE FOR THEM.
</p>
<p>We may grant access to third parties to our website in order to troubleshoot and/or maintain website,
database or infrastructure related issues. These access are monitored and removed after scope of
work is performed</p>

            <div className="main-sub-lable">ALL PAYMENTS ARE FINAL AND FOR EVALUATION PURPOSES ONLY.
            </div>
            <p>The registration fees are paid for allowing you to access the Funded Firm platform, models and services.
The Customer is not entitled to a refund of the registration fees as the service is directly delivered after
purchase. No refund applies to the service that Funded Firm offers.</p>




            <div className="main-sub-lable">1 – Online Registration Terms
            </div>
            <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your
state or province of residence, or that you are the age of majority in your state or province of
residence and you have given us your consent to allow any of your minor dependents to use this site.
</p>
  <p>You may not use our services for any illegal or unauthorized purpose nor may you, in the use of
the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
</p>
<p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>




            <div className="main-sub-lable">2 – General Conditions</div>
            <p>We reserve the right to refuse service to anyone for any reason at any time. You understand that your
content (not including credit card information), may be transferred unencrypted and involve (a)
transmissions over various networks; and (b) changes to conform and adapt to technical requirements
of connecting networks or devices. Credit card information is always encrypted during transfer over
networks.</p>
<p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the
Service, or access to the Service or any contact on the website through which the service is provided,
without express written permission by us.</p>
<p>The headings used in this agreement are included for convenience only and will not limit or
otherwise affect these Terms</p>
  
            <div className="main-sub-lable">3 – Accuracy, Completeness, and Timeliness of Information</div>
            <p>We make every effort to ensure that the information we provide is accurate, however the information is
also supplied by third parties and we are not responsible if information made available on this site is not
accurate, complete or current. The material on this site is provided for general information only and
should not be relied upon or used as the sole basis for making decisions without consulting primary,
more accurate, more complete or more timely sources of information. Any reliance on the material on
this site is at your own risk.
</p>
<p>This site may contain certain historical information. Historical information, necessarily, is not current and
is provided for your reference only. We reserve the right to modify the contents of this site at any time,
but we have no obligation to update any information on our site. You agree that it is your responsibility
to monitor changes to our site
</p>

<div>4 – Modifications to the Service and Prices
</div>
<p>Prices for our products are subject to change without notice.</p>
<p>We reserve the right at any time to modify or discontinue the Service (accounts provided) (or any part
or content thereof) without notice at any time.</p>
<p>We shall not be liable to you or to any third-party for any modification, price change, suspension or
discontinuance of the Service (The company has the right to seize operations at any given time).</p>
  

            <div className="main-sub-lable">5 – Products or Services (if applicabl)</div>
            <p>One registration per person is permitted. We reserve the right, but are not obligated, to limit the sales
of our products or Services to any person, geographic region or jurisdiction. We may exercise this right
on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we
offer. All descriptions of products or product pricing are subject to change at anytime without notice,
at our sole discretion. We reserve the right to discontinue any product at any time. Any offer for any
product or service made on this site is void where prohibited. We do not warrant that the quality of
any products, services, information, or other material purchased or obtained by you will meet your
expectations, or that any errors in the Service will be corrected.
</p>

<div  className="main-sub-lable">6 – Accuracy of Billing and Account Information</div>
<p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or
cancel quantities purchased per person, per household or per order. These restrictions may include
orders placed by or under the same customer account, the same credit card, and/or orders that use the
same billing and/or shipping address. In the event that we make a change to or cancel an order, we may
attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the
time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment,
appear to be placed by dealers, resellers or un-authorized distributors.</p>

<p>You agree to provide current, complete, and accurate purchase and account information for all purchases
made at our store. You agree to promptly update your account and other information, including your
email address and credit card numbers and expiration dates, so that we can complete your transactions
and contact you as needed.
</p>

<div  className="main-sub-lable">7 – Optional Tools</div>
<p>We may provide you with access to third-party tools over which we neither monitor nor have any control
nor input. You acknowledge and agree that we provide access to such tools on an ”as is” and “as
available” basis without any warranties, representations or conditions of any kind and without any
endorsement. We shall have no liability whatsoever arising from or relating to your use of optional
third-party tools. Any use by you of optional tools offered through the site is entirely at your own risk
and discretion and you should ensure that you are familiar with and approve of the terms on which tools
are provided by the relevant third-party provider(s). We may also, in the future, offer new services
and/or features through the website (including, the release of new tools and resources). Such new
features and/or services shall also be subject to these Terms of Services.
</p>

<div  className="main-sub-lable">8 – Third-party Links</div>
<p>Certain content, products and services available via our Service may include materials from third-parties.
Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are
not responsible for examining or evaluating the content or accuracy and we do not warrant and will not
have any liability or responsibility for any third-party materials or websites, or for any other materials,
products, or services of third-parties. We are not liable for any harm or damages related to the purchase
or use of goods, services, resources, content, or any other transactions made in connection with any
third-party websites. Please review carefully the third-party’s policies and practices and make sure you
understand them before you engage in any transaction. Complaints, claims, concerns, or questions
regarding third-party products should be directed to the third-party.
</p>

<div  className="main-sub-lable">9 – Personal Information</div>
<p>Your submission of personal information through the store is governed by our Privacy Policy.</p>

<div  className="main-sub-lable">10 – Prohibited Uses</div>
<p>10.1. In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using
the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any
unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local
ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property
rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to
submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious
code that will or may be used in any way that will affect the functionality or operation of the Service or
of any related website, other websites, or the Internet; (h) to collect or track the personal information of
others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral
purpose; or (k) to interfere with or circumvent the security features of the Service or any related website,
other websites, or the Internet. We reserve the right to terminate your use of the Service or any related
website for violating any of the prohibited uses.</p>
<p>10.2. Traders associated with proprietary trading firms, including their owners and employees, are
strictly prohibited from engaging in trading activities with Funded Firm.</p>


<div className="main-sub-lable">11 - Disclaimer of Warranties; Limitation of Liability</div>
<p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure
or error-free. We do not warrant that the results that may be obtained from the use of the service will be
accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of
time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability
to use, the service is at your sole risk. The service and all products and services delivered to you through the
service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any
representation, warranties or conditions of any kind, either express or implied, including all implied
warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability,
title, and non-infringement. In no case shall Intellimeter, our directors, officers, employees, affiliates, agents,
contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct,
indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost
profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in
contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the
service or any products procured using the service, or for any other claim related in any way to your use
of the service or any product, including, but not limited to, any errors or omissions in any content, or any
loss or damage of any kind incurred as a result of the use of the service or any content (or product)
posted, transmitted, or otherwise made available via the service, even if advised of their possibility.
Because some states or jurisdictions do not allow the exclusion or the limitation of liability for
consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the
maximum extent permitted by law.</p>

<div  className="main-sub-lable">12 – Indemnification</div>
<p>You agree to indemnify, defend and hold harmless Intellimeter and our parent, subsidiaries, affiliates,
partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers,
interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees,
made by any third-party due to or arising out of your breach of these Terms of Service or the
documents they incorporate by reference, or your violation of any law or the rights of a third-party.
</p>

<div  className="main-sub-lable">13 – Severability</div>
<p>In the event that any provision of these Terms of Service is determined to be unlawful, void or
unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by
applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of
Service, such determination shall not affect the validity and enforceability of any other remaining
provisions.
</p>



<div  className="main-sub-lable">Section 14 – Termination</div>
<p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the
termination of this agreement for all purposes. These Terms of Service are effective unless and until
terminated by either you or us. You may terminate these Terms of Service at any time by notifying
us that you no longer wish to use our Services, or when you cease using our site.</p>
<p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision
of these Terms of Service, we also may terminate this agreement at any time without notice and you
will remain liable for all amounts due up to and including the date of termination; and/or accordingly
may deny you access to our Services (or any part thereof).
</p>



<div  className="main-sub-lable">15 – Disputes</div>
<p>Once you dispute an order payment it has an adverse impact on the company, financially, and causes a
damage to our company profile in the eyes of the Payment Gateway. Hence, according to our policy,
we will be banning accounts (directly involved in the dispute/against the same order number) of
traders who dispute their payments and moving forward we will not entertain any requests to unban
the accounts(s).
</p>
<p>The user who is involved in a disputed transaction in the normal course of the business, where there has
not been any problem on our side, such users won't be eligible for any further accounts with Funding
Pips and all their other active accounts will be closed.
</p>
<p>This policy is in place to protect Funded Firm from any financial adversity as well as to ensure the
long term viability of the brand name.</p>



<div  className="main-sub-lable">16 – Entire Agreement</div>
<p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not
constitute a waiver of such right or provision. These Terms of Service and any policies or operating rules
posted by us on this site or in respect to The Service constitutes the entire agreement and understanding
between you and us and govern your use of the Service, superseding any prior or contemporaneous
agreements, communications and proposals, whether oral or written, between you and us (including,
but not limited to, any prior versions of the Terms of Service). Any ambiguities in the interpretation of
these Terms of Service shall not be construed against the drafting party.</p>



<div  className="main-sub-lable">17 – Changes to Terms of Service</div>
<p>You can review the most current version of the Terms of Service at any time at this page.
We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of
Service by posting updates and changes to our website. It is your responsibility to check our website
periodically for changes. Your continued use of or access to our website or the Service following the
posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>



<div  className="main-sub-lable">18 - Confidentiality of Communications
</div>
<p>All communications between Funded Firm ("The Company") and you, the user of our services, are strictly
confidential. This includes, but is not limited to, emails, messages through our website, phone calls, and any
other form of communication.</p>
<p>By using our site and engaging in our Service, you agree that you will not disclose, share, publish, or
otherwise make public any part of these communications without the prior written consent of The Company.
This confidentiality agreement is essential to maintain the trust and integrity of our services. Any breach of
this confidentiality provision will be considered a violation of these Terms and may result in immediate
termination of your access to our services, along with any other remedies available to The Company under
law.
</p>

<div  className="main-sub-lable">19 – Contact Information</div>
<p>Questions about the Terms of Service should be sent to us at <Link to="mailto:info@fundedfirm.com" target="_blank">info@fundedfirm.com</Link></p>


<div  className="main-sub-lable">20 - Applicable Law</div>
<p>Any legal relations established by these Terms and Conditions or related to them, as well as any related
non-contractual legal relations, shall be subject to the laws of Dubai, where applicable. Should there be a
dispute between the parties and if the said dispute is not able to be resolved through arbitration as
stated in the TOU by law or by circumstances, the dispute shall be resolved by the court of Dubai.</p>




  
          </div>
      </section>

    </>
  );
};
