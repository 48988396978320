import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PagePrivacy() {
  return (
    <>

      <Helmet>
        <title>Privacy Policy | Funded Firm</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Privacy Policy</div>
            </div>
            <div className="tlm-heading heading-24">Privacy Policy</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
          <div className="container-lg cl-custome legal-content">
            <p>Protecting your private information is our priority. This Statement of Privacy applies to fundingpips.com,
and governs data collection and usage.</p>
            <p>Funded Firm website is an eCommerce site. By using Funded Firm website, you consent to the data
            practices described in this Statement and all extensions and addendums to this Statement.</p>


            <div className="main-sub-lable">Collection of your Personal Information</div>
            <p>In order to better provide you with products and services offered, Funded Firm may collect
            personally identifiable information, such as your:</p>

            <ul>
              <li>First and Last Name</li>
              <li>Home Address</li>
              <li>Mailing Address</li>
              <li>E-mail Address</li>
              <li>Phone Number</li>
              <li>Financial Information</li>
            </ul>

            <p>If you purchase Funded Firm products and services, we collect billing and credit card information. This
information is used to complete the purchase transaction or issue payments to you. We do not collect
any personal information about you unless you voluntarily provide it to us. However, you may be
required to provide certain personal information to us when you elect to use certain products or
services. These may include: (a) registering for an account; (b) entering a sweepstake or contest
sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d)
sending us an email message; (e) submitting your credit card or other payment information when
ordering and purchasing products and services. To with, we will use your information for, but not
limited to, communicating with you in relation to services and/or products you have requested from us.
We also may gather additional personal or non-personal information in the future for the same purpose.</p>


<div className="main-sub-lable">Use of your Personal Information</div>
<p>Funded Firm collects and uses your personal information to operate and deliver the services you
have requested. Funded Firm may also use your personally identifiable information to inform you
of other products or services available from Funded Firm and its affiliate</p>


<div className="main-sub-lable">Sharing Information with Third Parties</div>
<p>Funded Firm may sell, rent, or lease customer information to third parties for the following reasons: We
will collect this data and provide it to third parties if it is a necessary step in the User account creation
process, contributes to the ease of use, reliability, or functionality of our products, or if it is of mutual
interest to Funded Firm. Funded Firm may, from time to time, contact you on behalf of external business
partners about a particular offering that may be of interest to you. In those cases, your unique personally
identifiable information (e-mail, name, address, telephone number) is transferred to the third party.
Funded Firm may share data with trusted partners to help perform statistical analysis, send you email or
postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from
using your personal information except to provide these services to Funded Firm, and they are required to
maintain the confidentiality of your information. Funded Firm may disclose your personal information,
without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a)
conform to the edicts of the law or comply with legal process served on Funded Firm or the Site; (b)
protect and defend the rights or property of Funded Firm; and/or (c) act under exigent circumstances to
protect the personal safety of users of Funded Firm, or the public.</p>

<div className="main-sub-lable">Tracking User Behavior</div>
<p>Funded Firm may keep track of the websites and pages our users visit within Funded Firm, in order to
determine what Funded Firm services are the most popular. This data is used to deliver customized
content and advertising within Funded Firm to customers whose behavior indicates that they are
interested in a particular subject area.</p>

<div className="main-sub-lable">Automatically Collected Information</div>
<p>Information about your computer hardware and software may be automatically collected by Funded Firm.
This information can include your IP address, browser type, domain names, access times, and referring
website addresses. This information is used for the operation of Page 3 of 5 services, to maintain the
quality of the service, and to provide general statistics regarding the use of the Funded Firm website.</p>

<div className="main-sub-lable">Use of Cookies</div>
<p>Funded Firm website may use cookies to help you personalize your online experience. A cookie is a text file
that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver
viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the
domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience
feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific
page. For example, if you personalize Funded Firm pages, or register with Funded Firm site or services, a
cookie helps Funded Firm to recall your specific information on subsequent visits. This simplifies the process
of recording your personal information, such as billing addresses, shipping addresses, and so on. When you
return to the same Funded Firm website, the information you previously provided can be retrieved, so you
can easily use Funded Firm features that you customized. You have the ability to accept or decline
cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser
setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully
experience the interactive features of Funded Firm services or websites you visit.</p>

<div className="main-sub-lable">Links</div>
<p>This website contains links to other sites. Please be aware that we are not responsible for the content or
privacy practices of such other sites. We encourage our users to be aware when they leave our site and to
read the privacy statements of any other site that collects personally identifiable information.
</p>

<div className="main-sub-lable">Security of your Personal Information
</div>
<p>Funded Firm secures your personal information from unauthorized access, use, or disclosure. Funding
Pips uses the following methods for this purpose:
</p>


<div className="main-sub-lable">SSL Protocol</div>
<p>When personal information (such as a credit card number) is transmitted to other websites, it is protected
through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take
appropriate security measures to protect against unauthorized access to or alteration of your personal
information. Unfortunately, no data transmission over the Internet or any wireless network can be
guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you
acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond
our control; and (b) security, integrity, and privacy of any and all information and data exchanged
between you and us through this Site cannot be guaranteed.
</p>

<div className="main-sub-lable">Right to Deletion</div>
<p>Subject to certain exceptions and for those individuals who meet jurisdictional and legal
requirements, on receipt of a verifiable request from you, we will:
</p>
<p>Delete your personal information from our records; and
Direct any service providers to delete your personal information from their records.</p>



<div className="main-sub-lable">Children Under Thirteen</div>
<p>Funded Firm does not knowingly collect personally identifiable information from children under the age
of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to
use this website.</p>
<div>Opt-Out & Unsubscribe from Third Party Communications</div>
<p>We respect your privacy and give you an opportunity to opt out of receiving announcements of certain
information. Users may opt-out of receiving any or all communications from third-party partners of
Funded Firm by contacting us here:</p>
<p>please email us at <Link to="mailto:info@fundedfirm.com" target="_blank">info@fundedfirm.com</Link></p>


<div className="main-sub-lable">E-mail Communications</div>
<p>From time to time, Funded Firm may contact you via email for the purpose of providing
announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.
In order to improve our Services, we may receive a notification when you open an email from Funding
Pips or click on a link therein.</p>

<p>If you would like to stop receiving marketing or promotional communications via email from Funding
Pips, you may opt out of such communications Customers may unsubscribe from emails by clicking on
the unsubscribe button found at the bottom of each email.</p>

<div className="main-sub-lable">External Data Storage</div>
<p>We may store your data on servers provided by third-party hosting vendors with whom we have
contracted.</p>

<div className="main-sub-lable">Changes to this Statement</div>
<p>Funded Firm reserves the right to change this Privacy Policy from time to time. We will notify you about
significant changes in the way we treat personal information by sending a notice to the primary email
address specified in your account, placing a prominent notice on our website, and/or updating any
privacy information. Your continued use of the website and/or Services available after such modifications
will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and
be bound by that Policy</p>

<div className="main-sub-lable">Contact Information</div>
<p>Funded Firm welcomes your questions or comments regarding this Statement of Privacy. If you believe that
Funded Firm has not adhered to this Statement, please contact Funded Firm at support@fundingpips.com
This statement is effective as of June 1st, 2024.</p>


          </div>
      </section>


      

    </>
  );
};
