import React, { useState } from 'react'

// Icpns
// import Icons from '../../components/Icons';

export default function Pricing() {

    // Pricing section Tabbing Js
    const [pricing, setPricing] = useState('pricing1');

    const handlePricingClick = (pricingSectionId) => {
        setPricing(pricingSectionId);
    };

  return (
    <>
    <div className="common-heading-bx">
        <div className="common-heading ch-big text-center">Our Evaluations</div>
    </div>

    <div className='mt-4'>
        <div className="common-tabing-bx">
            <div className={`ctb-item ${pricing === 'pricing1' ? 'active' : ''}`} onClick={() => handlePricingClick('pricing1')}>$25,000</div>
            <div className={`ctb-item ${pricing === 'pricing2' ? 'active' : ''}`} onClick={() => handlePricingClick('pricing2')}>$50,000</div>
            <div className={`ctb-item ${pricing === 'pricing3' ? 'active' : ''}`} onClick={() => handlePricingClick('pricing3')}>$1,00,000</div>
            <div className={`ctb-item ${pricing === 'pricing4' ? 'active' : ''}`} onClick={() => handlePricingClick('pricing4')}>$2,00,000</div>
        </div>


        {/* <div className="d-none d-lg-block"> */}
            <div className="row justify-content-center py-4">
                <div className={`col-lg-3 pricing-item ${pricing === 'pricing1' ? 'active' : 'd-none d-lg-block'}`} onClick={() => handlePricingClick('pricing1')} id="pricing1">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Trading Period</div>
                            <div className="pmb-data-price">Infinity</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Minimum Trading Days</div>
                            <div className="pmb-data-price">0 days</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Daily Loss <span>(5%)</span></div>
                            <div className="pmb-data-price">$1,250</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Loss <span>(10%)</span></div>
                            <div className="pmb-data-price">$1,250</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(12%)</span></div>
                            <div className="pmb-data-price">$3,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Leverage</div>
                            <div className="pmb-data-price">1:50</div>
                        </div>

                        <div>
                            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className={`col-lg-3 pricing-item ${pricing === 'pricing2' ? 'active' : 'd-none d-lg-block'}`} onClick={() => handlePricingClick('pricing2')} id="pricing2">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Trading Period</div>
                            <div className="pmb-data-price">Infinity</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Minimum Trading Days</div>
                            <div className="pmb-data-price">1 days</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Daily Loss <span>(5%)</span></div>
                            <div className="pmb-data-price">$1,250</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Loss <span>(10%)</span></div>
                            <div className="pmb-data-price">$2,500</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(12%)</span></div>
                            <div className="pmb-data-price">$6,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Leverage</div>
                            <div className="pmb-data-price">1:50</div>
                        </div>

                        <div>
                            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className={`col-lg-3 pricing-item ${pricing === 'pricing3' ? 'active' : 'd-none d-lg-block'}`} onClick={() => handlePricingClick('pricing3')} id="pricing3">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Trading Period</div>
                            <div className="pmb-data-price">Infinity</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Minimum Trading Days</div>
                            <div className="pmb-data-price">2 days</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Daily Loss <span>(5%)</span></div>
                            <div className="pmb-data-price">$5,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Loss <span>(10%)</span></div>
                            <div className="pmb-data-price">$10,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(12%)</span></div>
                            <div className="pmb-data-price">$12,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Leverage</div>
                            <div className="pmb-data-price">1:50</div>
                        </div>

                        <div>
                            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>

                <div className={`col-lg-3 pricing-item ${pricing === 'pricing4' ? 'active' : 'd-none d-lg-block'}`} onClick={() => handlePricingClick('pricing4')} id="pricing4">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Trading Period</div>
                            <div className="pmb-data-price">Infinity</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Minimum Trading Days</div>
                            <div className="pmb-data-price">3 days</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Daily Loss <span>(5%)</span></div>
                            <div className="pmb-data-price">$10,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Maximum Loss <span>(10%)</span></div>
                            <div className="pmb-data-price">$20,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(12%)</span></div>
                            <div className="pmb-data-price">$24,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Leverage</div>
                            <div className="pmb-data-price">1:50</div>
                        </div>

                        <div>
                            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
            </div>
        {/* </div> */}
        

        {/* <div className="d-block d-lg-none">
            <div className={`row justify-content-center pricing-row  py-4 ${pricing === 'pricing1' ? 'active' : 'd-none'}`} id="pricing1">
                <div className="col-lg-4 col-md-5 active">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(10% / 5%)</span></div>
                            <div className="pmb-data-price">$2,500/$1,250</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Daily Drawdown <span>(5%)</span></div>
                            <div className="pmb-data-price">$1,250</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Max Drawdown <span>(10%)</span></div>
                            <div className="pmb-data-price">$2,500</div>
                        </div>

                        <div>
                        <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="pricing-included-heading">What’s included?</div>
                    <div className="check-list-bx pricing-included-bx">
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Up-to 100% profit split*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> 14-Day or 7-Day Payouts</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Unlimited Trading Days</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> News trading allowed</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Zero commissions*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Max 2% loss per trade idea</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Minimum trading days: 0</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Weekend/Overnight holding</div>
                    </div>
                </div>
            </div>

            <div className={`row justify-content-center pricing-row  py-4 ${pricing === 'pricing2' ? 'active' : 'd-none'}`} id="pricing2">
                <div className="col-lg-4 col-md-5 active">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(10% / 5%)</span></div>
                            <div className="pmb-data-price">$5,000/$2,500</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Daily Drawdown <span>(5%)</span></div>
                            <div className="pmb-data-price">$2,500</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Max Drawdown <span>(10%)</span></div>
                            <div className="pmb-data-price">$5,000</div>
                        </div>

                        <div>
                        <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="pricing-included-heading">What’s included?</div>
                    <div className="check-list-bx pricing-included-bx">
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Up-to 100% profit split*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> 14-Day or 7-Day Payouts</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Unlimited Trading Days</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> News trading allowed</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Zero commissions*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Max 2% loss per trade idea</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Minimum trading days: 0</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Weekend/Overnight holding</div>
                    </div>
                </div>
            </div>

            <div className={`row justify-content-center pricing-row  py-4 ${pricing === 'pricing3' ? 'active' : 'd-none'}`} id="pricing3">
                <div className="col-lg-4 col-md-5 active">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(10% / 5%)</span></div>
                            <div className="pmb-data-price">$10,000/$5,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Daily Drawdown <span>(5%)</span></div>
                            <div className="pmb-data-price">$5,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Max Drawdown <span>(10%)</span></div>
                            <div className="pmb-data-price">$10,000</div>
                        </div>

                        <div>
                        <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="pricing-included-heading">What’s included?</div>
                    <div className="check-list-bx pricing-included-bx">
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Up-to 100% profit split*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> 14-Day or 7-Day Payouts</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Unlimited Trading Days</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> News trading allowed</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Zero commissions*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Max 2% loss per trade idea</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Minimum trading days: 0</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Weekend/Overnight holding</div>
                    </div>
                </div>
            </div>

            <div className={`row justify-content-center pricing-row  py-4 ${pricing === 'pricing4' ? 'active' : 'd-none'}`} id="pricing4">
                <div className="col-lg-4 col-md-5 active">
                    <div className="pricing-main-bx">

                        <div className="pmb-item">
                            <div className="pmb-heading">Profit Target <span>(10% / 5%)</span></div>
                            <div className="pmb-data-price">$20,000/$10,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Daily Drawdown <span>(5%)</span></div>
                            <div className="pmb-data-price">$10,000</div>
                        </div>

                        <div className="pmb-item">
                            <div className="pmb-heading">Max Drawdown <span>(10%)</span></div>
                            <div className="pmb-data-price">$20,000</div>
                        </div>

                        <div>
                        <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-outline">Get Funded</div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="pricing-included-heading">What’s included?</div>
                    <div className="check-list-bx pricing-included-bx">
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Up-to 100% profit split*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> 14-Day or 7-Day Payouts</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Unlimited Trading Days</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> News trading allowed</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Zero commissions*</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Max 2% loss per trade idea</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Minimum trading days: 7</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg2 /></div> Weekend/Overnight holding</div>
                    </div>
                </div>
            </div>
        </div> */}

    </div>

    </>
  )
}
