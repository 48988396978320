import React from 'react'

// Icons File
import Icons from '../../components/Icons'

// Img
import banner03 from "../../img/bgimg/banner(03).webp"

// Css File
import "../../css/ReadMoreBox.css"

export default function BecomePro() {
    return (
    <>
        {/* <div className="row rci-gap justify-content-center">

            <div className="col-12 d-block d-md-none order-1">
                <div className="common-heading-bx mb-3">
                    <div className="common-heading ch-big">Become a Pro Trader</div>
                </div>
            </div>

            <div className="col-lg-7 col-md-7 order-3 order-md-1">
                <div className="common-heading-bx d-none d-md-block">
                    <div className="common-heading ch-big">Become a Pro Trader</div>
                </div>
                <div className="row rci-gap mt-4">
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-01-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">A reliable trading platform is essential for success</div>
                                <div className="rci-text">Investing can be risky business, so choosing the right broker to help you trade stocks and other financial instruments is essential. When it comes to investing, you need a reliable online trading platform that provides safe and comfortable trading experience, making it easier to manage your investment while taking into account the risks at hand.</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-01-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">A reliable trading platform is essential for success</div>
                                <div className="rci-text">Investing can be risky business, so choosing the right broker to help you trade stocks and other financial instruments is essential. When it comes to investing, you need a reliable online trading platform that provides safe and comfortable trading experience, making it easier to manage your investment while taking into account the risks at hand.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-lg-5 col-md-5 order-2 order-md-2" data-aos="fade-left" data-aos-delay="400">
                <div className="readmore-card-img-bx">
                    <img className='grayscale-img ynimation' src={banner03} alt="" />
                </div>
            </div>

        </div> */}

        <div className="row rci-gap">

            <div className="col-12 d-block d-md-none order-1">
                <div className="common-heading-bx mb-3">
                    <div className="common-heading ch-big">Become a Pro Trader</div>
                </div>
            </div>

            <div className="col-lg-7 col-md-7 order-3 order-md-1">
                <div className="common-heading-bx d-none d-md-block">
                    <div className="common-heading ch-big">Become a Pro Trader</div>
                </div>
                <div className="row rci-gap mt-4">
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-01-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">Trade the way you want</div>
                                <div className="rci-text">Use an "EA", hold during news, hold trades over the weekend and trade lot sizes as big as the leverage allows, all while adhering to our trading rules.</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-02-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">Unlimited Trading Days</div>
                                <div className="rci-text">We understand that putting a time limit creates a stressful environment, therefore we have no minimum or maximum limit on trading days. Take all the time you need.</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-03-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">Scaling Plan</div>
                                <div className="rci-text">Scale your way up with us. We increase the account size, Maximum Loss, and Maximum Daily Loss to a 14% Maximum Loss and a 7% Daily Loss limit. Once the trader receives a 'Hot Seat' at the firm, they can trade up to $2 million with on-demand payouts and a monthly bonus. Read more about our Scaling Plan.</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="readmore-card-item" data-bs-target="#RCI-04-Modal" data-bs-toggle="modal">
                            <div className="rci-icon-bx"><Icons.PlusSvg /></div>
                            <div className="rci-data-bx">
                                <div className="rci-heading">Payout System</div>
                                <div className="rci-text">Request your payout every Tuesday with an 80% profit split. Same day to 4 trading days (Every Tuesday), depending on the start day of the Master account. Once a trader earns a Hot Seat in our firm, the trader receives on-demand payouts with a 90% profit split.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-lg-5 col-md-5 order-2 order-md-2" data-aos="fade-left" data-aos-delay="400">
                <div className="readmore-card-img-bx">
                    <img className='grayscale-img ynimation' src={banner03} alt="" />
                </div>
            </div>

        </div>

        {/* --- Modal Start --- */}

        {/* RCI-01-Modal */}
        <div className="modal fade zoom" id="RCI-01-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-content">
                    <div className="custom-modal-header cmh-px-50">
                        <div className="cmh-lable">Trade the way you want</div>
                        <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="custom-modal-data px-3 pb-3">
                        <div>Use an "EA", hold during news, hold trades over the weekend and trade lot sizes as big as the leverage allows, all while adhering to our trading rules.</div>
                    </div>
                </div>
            </div>
        </div>
        {/* --- Modal End --- */}

        {/* RCI-02-Modal */}
        <div className="modal fade zoom" id="RCI-02-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-content">
                    <div className="custom-modal-header cmh-px-50">
                        <div className="cmh-lable">Unlimited Trading Days</div>
                        <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="custom-modal-data px-3 pb-3">
                        <div>We understand that putting a time limit creates a stressful environment, therefore we have no minimum or maximum limit on trading days. Take all the time you need.</div>
                    </div>
                </div>
            </div>
        </div>
        {/* --- Modal End --- */}

        {/* RCI-03-Modal */}
        <div className="modal fade zoom" id="RCI-03-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-content">
                    <div className="custom-modal-header cmh-px-50">
                        <div className="cmh-lable">Scaling Plan</div>
                        <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="custom-modal-data px-3 pb-3">
                        <div>Scale your way up with us. We increase the account size, Maximum Loss, and Maximum Daily Loss to a 14% Maximum Loss and a 7% Daily Loss limit. Once the trader receives a 'Hot Seat' at the firm, they can trade up to $2 million with on-demand payouts and a monthly bonus. Read more about our Scaling Plan.</div>
                    </div>
                </div>
            </div>
        </div>
        {/* --- Modal End --- */}

        {/* RCI-04-Modal */}
        <div className="modal fade zoom" id="RCI-04-Modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-content">
                    <div className="custom-modal-header cmh-px-50">
                        <div className="cmh-lable">Payout System</div>
                        <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>
                    <div className="custom-modal-data px-3 pb-3">
                        <div>Request your payout every Tuesday with an 80% profit split. Same day to 4 trading days (Every Tuesday), depending on the start day of the Master account. Once a trader earns a Hot Seat in our firm, the trader receives on-demand payouts with a 90% profit split.</div>
                    </div>
                </div>
            </div>
        </div>
        {/* --- Modal End --- */}
    </>
    )
}
